import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from './Layout';
import BlockContent from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from './sanity-client';

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      _rawBody
      title
      subTitle
      enumerations {
        text
        title
        order
        color
        linktext
        link
        keywords
        image {
          asset {
            fluid(maxHeight: 700, maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

const PageTemplate = props => {
  const { data } = props;

  const page = data && data.sanityPage;

  const builder = imageUrlBuilder(sanityClient);

  const urlFor = source => {
    return builder.image(source);
  };

  const serial = {
    types: {
      imageBlog: props => {
        return (
          <span
            className={`image ${
              props.node.direction === 'Links'
                ? 'left'
                : props.node.direction === 'Voll'
                ? 'fit'
                : 'right'
            }`}
            style={{ zIndex: 1 }}
          >
            <img
              src={urlFor(props.node.image.asset).url()}
              alt={props.node.image.asset._ref}
            />
          </span>
        );
      },
    },
  };
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{page.title}</h2>
            <p>{page.subTitle}</p>
          </div>
        </header>
        {page._rawBody && (
          <div className="wrapper">
            <div className="inner">
              <BlockContent blocks={page._rawBody} serializers={serial} />
            </div>
          </div>
        )}
      </section>

      <section id="wrapper">
        {page.enumerations.map((num, index) => (
          <section
            key={num.id}
            id={num.title}
            className={`wrapper spotlight ${index % 2 ? '' : 'alt'} style${
              num.color
            }`}
          >
            <div className="inner">
              {num.image && (
                <Img
                  className="image"
                  fluid={num.image.asset.fluid}
                  alt={num.name}
                />
              )}

              <div className="content">
                <h2 className="major">{num.title}</h2>
                <p>{num.text}</p>
                {num.link && (
                  <Link to={`../${num.link}`} className="special">
                    {num.linktext}
                  </Link>
                )}
              </div>
            </div>
            <div style={{ display: 'none' }}>{num.keywords}</div>
          </section>
        ))}
      </section>
    </Layout>
  );
};

export default PageTemplate;
